import {AppBar, CssBaseline, Toolbar, Typography } from '@mui/material'
//import {CottageIcon} from '@mui/icons-material'
import CottageIcon from '@mui/icons-material/Cottage';
import { Container } from '@mui/system';

function App() {
  return (
    <>
     <CssBaseline/>
     <AppBar position='relative'>
      <Toolbar>
        <CottageIcon/>
        <Typography variant='h6' >Home screen</Typography>
      </Toolbar>
     </AppBar>
     <main>
        <div>
          <Container maxWidth="sm">
                <Typography variant='h3' align='center' color='textPrimary' gutterBottom>The body content</Typography>
          </Container>
        </div>
     </main>
    </>
  );
}

export default App;
